import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { SEOHeaders } from "./SeoHeaders"
import { config } from './config'

interface AppProps {
}

type AppStates = {
  email: string
  emailerror: string
  emailsuccess: string
}

class App extends Component <AppProps, AppStates> {
  constructor (props: AppProps) {
    super(props)
    this.state = {
      email: '',
      emailerror: '',
      emailsuccess: ''
    }
  }
  
  subscribe() {
    this.setState({
      emailerror: '',
      emailsuccess: ''
    })
    fetch(
      `${config.apiUri}/api/v1/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.email
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            email: '',
            emailsuccess: json.message
          })
        } else {
          this.setState({
            emailerror: json.message
          })
        }
      })
      .catch((e)=>{
        
      })
  }
  
  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as AppStates)
  }

  render(){
    return (
      <div>
        <SEOHeaders/>
        <div className="relative isolate overflow-hidden bg-white">
          <svg
            aria-hidden="true"
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <rect fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" width="100%" height="100%" strokeWidth={0} />
          </svg>
          <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-24">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
              <div className="mt-24 sm:mt-32 lg:mt-16">
                <div className="inline-flex space-x-6">
                  <span className="rounded-full bg-[#FF5252] px-3 py-1 text-sm font-semibold leading-6 text-white ring-1 ring-inset ring-indigo-600/10">
                    Status
                  </span>
                  <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                    <span>Development</span>
                  </span>
                </div>
              </div>
              <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Next-gen video platform
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Beeps.tv lets creators and communities co-own and manage video channels through DAOs. Users can submit, curate, and vote on content, creating a transparent, community-driven platform for videos.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <Link
                  to={"https://discord.gg/4mwf43Af9k"}
                  className="rounded-md bg-[#FF5252] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#FF5252] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Join Discord
                </Link>
                <a href={'#subscribe'} className="text-sm font-semibold leading-6 text-gray-900">
                  Subscribe <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <video
                    className="w-[68rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                    width="2410"
                    height="1516"
                    autoPlay
                    loop
                  >
                    <source src="main.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our mission</h2>
              <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                  <p className="text-xl leading-8 text-gray-600">
                    At Beeps.tv, our mission is to create a shared video platform that empowers creators of all kinds - from amateurs to large corporations - to co-own and manage livestream channels in an inclusive media space.
                  </p>
                  <p className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                    As AI-generated content grows, we recognize the urgent need for advanced curation strategies. By leveraging cutting-edge blockchain technologies and decentralized autonomous organizations (DAOs), we are decentralizing content ownership and governance, giving communities the tools to shape their own channels and curate content transparently and collaboratively. Arcloop is driving the future of decentralized media, where curators, creators and audiences have full control.
                  </p>
                </div>
                <div className="lg:flex lg:flex-auto lg:justify-center">
                  <dl className="w-64 space-y-8 xl:w-80">
                    {stats.map((stat) => (
                      <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                        <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                        <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white py-16 sm:py-24 lg:py-32">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
            <div className="max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7">
              <h2 className="inline sm:block lg:inline xl:block">Interested in news and updates?</h2>
              <p className="inline sm:block lg:inline xl:block">Sign up for our newsletter.</p>
            </div>
            <form className="w-full max-w-md lg:col-span-5 lg:pt-2" id="subscribe">
              <div className="flex gap-x-2">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  name="email"
                  type="email"
                  required
                  value={this.state.email}
                  onChange={this.inputChange}
                  placeholder="Enter your email"
                  className="min-w-0 flex-auto rounded-md px-3.5 py-2 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 focus:ring-[#FF5252] placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {this.state.emailsuccess === '' ?
                  <button
                    onClick={()=>this.subscribe()}
                    type="button"
                    className="flex-none rounded-md bg-[#FF5252] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
                  >
                    Subscribe
                  </button>
                :null}
                {this.state.emailsuccess !== '' ?
                  <button
                    onClick={()=>this.subscribe()}
                    type="button"
                    className="flex-none rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
                  >
                    Subscribed!
                  </button>
                :null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-900">
                {/*
                You can also join us on {" "}
                <Link to={"https://discord.gg/4mwf43Af9k"} className="font-semibold">
                  Discord
                </Link>
                {" "} or {" "}
                */}
                <Link to={"mailto:contact@beeps.tv"} className="font-semibold">
                  Email us
                </Link>
              </p>
            </form>
          </div>
        </div>
        
        <footer aria-labelledby="footer-heading" className="bg-white">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-12 lg:px-8 lg:pt-16">
            <div className="mt-4 border-t border-gray-900/10 pt-8 sm:mt-8 lg:mt-12">
              <p className="text-xs leading-5 text-gray-500">&copy; 2024 ARCLOOP LIMITED. All rights reserved.</p>
            </div>
          </div>
        </footer>
        
      </div>
    );
  }
}

export default App;

const stats = [
  { label: 'Of videos uploaded daily', value: '5000+ hours' },
  { label: 'Gen-Z,M watched videos during pandemic', value: '61%' },
  { label: 'Of internet traffic is video', value: '82%' },
]